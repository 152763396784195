<template>
  <Form
    class="comments-form mb-4"
    v-if="comment"
    @submit="onSubmit"
    name="comment-create"
    @reset="handleReset"
  >
    <div class="row">
      <div class="col-12">
        <FloatingTextarea
          v-if="comment"
          :placeholder="trans['Enter text']"
          name="message"
          prefix="comment-inline-message"
          rules="required"
          v-model="comment.message"
          :error="fieldErrors.message"
          :reset="resetMessage"
          @field-reset="resetMessage = false"
          class="mb-2"
        />
      </div>
    </div>
    <div class="row g-2">
      <div class="col-md-6 col-lg-4 col-xl-5">
        <FloatingTextInput
          v-if="!user"
          name="name"
          type="text"
          autocomplete="given-name"
          prefix="comment-inline-name"
          :placeholder="trans['Enter name']"
          rules="required"
          class="mb-2"
          v-model="comment.name"
          :error="fieldErrors.name"
          :reset="resetName"
        />
      </div>
      <div class="col-md-6 col-lg-4 col-xl-5">
        <div v-if="!user">
          <FloatingTextInput
            v-if="!user"
            prefix="comment-inline-email"
            name="email"
            type="email"
            autocomplete="email"
            class="mb-2"
            rules="required|email"
            :placeholder="trans['Enter e-mail']"
            v-model="comment.email"
            :error="fieldErrors.email"
            :reset="resetEmail"
          />
        </div>
      </div>
      <div class="col-lg-4 col-xl-2 text-end">
        <button
          class="btn btn-primary w-100 btn-submit align-self-start"
          type="submit"
          :disabled="submited"
        >
          {{ trans["Submit"] }}
        </button>
      </div>
    </div>
    <div class="row">
      <AuthModal
        v-if="comment && comment.email"
        :is-show="authShow"
        :email="comment.email"
        @login="onLogin"
      />
    </div>
  </Form>
</template>
<script>
import { Form, useForm } from "vee-validate";
import FloatingTextInput from "../share/FloatingTextInput.vue";
import FloatingTextarea from "../share/FloatingTextarea.vue";
import { ref, watch, onMounted, inject, computed, reactive } from "vue";
import { useStore } from "vuex";
import { getMsgError, getFieldError } from "../../utils/requests";
import AuthModal from "../share/AuthModal.vue";

export default {
  components: {
    Form,
    FloatingTextInput,
    FloatingTextarea,
    AuthModal,
  },

  setup(props, { emit }) {
    const toast = inject("$toast");
    const submited = ref(false);
    const comment = ref(null);
    const store = useStore();
    const user = computed(() => store.state.user);
    const post_id = computed(() => store.state.comments.post_id);
    const resetMessage = ref(false);
    const resetEmail = ref(false);
    const resetName = ref(false);
    const trans = JSON.parse(inject("trans"));

    const authShow = ref(false);
    const fieldErrors = reactive({
      email: null,
      name: null,
      message: null,
    });
    const { setFieldError, handleReset, resetForm } = useForm();
    const resetFieldErrors = () => {
      for (let key in fieldErrors) {
        fieldErrors[key] = null;
      }
    };
    resetFieldErrors();
    onMounted(() => {
      store
        .dispatch("comments/comment_default")
        .then((item) => {
          comment.value = JSON.parse(JSON.stringify(item));
        })
        .catch((err) => {})
        .finally(() => (submited.value = false));
    });

    watch(user, (newData, oldData) => {
      if (newData && newData != oldData) {
        authShow.value = false;
      }
      resetFieldErrors();
    });

    const onLogin = () => {
      onSubmit({ message: comment.value.message });
    };
    const onSubmit = (values) => {
      submited.value = true;
      authShow.value = false;
      axios
        .post(`/ajax/comments/${post_id.value}`, {
          ...values,
        })
        .then((resp) => {
          store.commit("comments/comments_reload");

          store
            .dispatch("comments/comment_default")
            .then((item) => {
              comment.value = JSON.parse(JSON.stringify(item));
              emit("created", comment);
            })
            .catch((err) => {})
            .finally(() => (submited.value = false));
          toast.success(resp.data.message);
          resetMessage.value = true;
        })
        .catch((err) => {
          let field = getFieldError(err);

          if (err && err.response && err.response.status == 401) {
            authShow.value = true;
          }
          if (field) {
            setFieldError(field, getMsgError(err));
            fieldErrors[field] = getMsgError(err);
          } else {
            toast.error(getMsgError(err));
          }
        })
        .finally(() => {
          submited.value = false;
        });
    };

    return {
      user,
      toast,
      comment,
      submited,
      fieldErrors,
      handleReset,
      setFieldError,
      onSubmit,
      onLogin,
      authShow,
      resetMessage,
      resetEmail,
      resetName,
      trans,
    };
  },
};
</script>
