<template>
  <div>
    <Form @submit="onSubmit">
      <div class="row row-cols-2 g-2">
        <div class="col-12 col-sm-12">
          <input
            type="email"
            disabled
            class="form-control is-valid"
            :value="email"
            autocomplete="off"
          />
        </div>
        <div class="col-12 col-sm-12">
          <FloatingTextInput
            v-if="!user"
            prefix="login-modal"
            name="password"
            type="password"
            autocomplete="current-password"
            label-class="text-secondary"
            :placeholder="trans['Enter password']"
            rules="required"
            v-model="password"
            :error="fieldErrors.password"
            :is-error="!!message_error"
          />
        </div>
        <div class="col-12" v-if="message_error">
          <div class="alert alert-danger alert-dismissible m-2" role="alert">
            <button
              type="button"
              class="btn-close"
              :disabled="submited"
              @click.stop="message_error = null"
              aria-label="Close"
            ></button>
            <small>{{ message_error }}</small>
          </div>
        </div>
        <div
          class="col-12 col-sm-12 d-flex flex-row align-items-center justify-content-center"
        >
          <div
            class="d-flex flex-row justify-content-start align-items-center"
            v-if="submited"
          >
            <span class="spinner-grow text-primary" role="status"></span>
          </div>
          <button
            type="submit"
            class="btn btn-primary w-100"
            :disabled="submited"
            v-if="!submited"
          >
            {{ trans["SignIn"] }}
          </button>
        </div>
        <div class="col-12">
          <a
            class="btn btn-link px-0 text-center w-100"
            href="/forgot-password"
            target="_blank"
            :disabled="submited"
          >
            {{ trans["Forgot Password"] }}
          </a>
        </div>
      </div>
    </Form>
  </div>
</template>
<script>
import { getFieldError, getMsgError, getMsgSuccess } from "../../utils/requests";
import { computed, inject, reactive, ref, toRef, watch } from "vue";
import { useStore } from "vuex";
import FloatingTextInput from "./FloatingTextInput.vue";
import { Form, useForm } from "vee-validate";
import { useToast } from "vue-toast-notification";

export default {
  components: {
    FloatingTextInput,
    Form,
  },

  props: {
    email: {},
  },

  setup(props, { emit }) {
    const toast = useToast();
    const store = useStore();
    const user = computed(() => store.state.user);
    const submited = ref(false);
    const password = ref(null);
    const message_error = ref(null);
    const trans = JSON.parse(inject("trans"));
    const profile = inject("profile");
    console.log(profile);
    const fieldErrors = reactive({
      email: null,
      password: null,
    });
    const { setFieldError, handleReset, resetForm } = useForm();

    const onSubmit = () => {
      submited.value = true;
      resetFieldErrors();

      axios
        .post(
          "/ajax/login",
          {
            email: props.email,
            password: password.value,
          },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((resp) => {
          message_error.value = null;
          store.commit("user", resp.data.user);
          emit("login");
          document.querySelectorAll(".btn-header-auth").forEach((el) => el.remove());
          const avatar = document.createElement("a");
          avatar.href = profile;
          avatar.classList.add("user-avatar");
          if (!resp.data.user.avatar) {
            avatar.classList.add("user-avatar-default");
          } else {
            const img = document.createElement("img");
            img.src = resp.data.user.avatar;
            avatar.append(img);
          }
          document.getElementById("navbar-header-container").append(avatar);
          if (resp.data.user && resp.data.user.privacy_offer) {
            let alertPrivacyOffer = document.getElementById("alert-privacy-offer");
            if (alertPrivacyOffer) {
              alertPrivacyOffer.classList.add("removed");
              alertPrivacyOffer.addEventListener("transitionend", () => {
                alertPrivacyOffer.remove();
              });
            }
          }
          document.documentElement.setAttribute(
            "data-bs-theme",
            resp.data.user.theme == "dark" ? "dark" : "light"
          );
        })
        .catch((err) => {
          let field = getFieldError(err);
          if (err && err.response && err.response.status == 401) {
          }
          if (field) {
            field, getMsgError(err);
            fieldErrors["password"] = getMsgError(err);
          } else {
            message_error.value = getMsgError(err);
          }
        })
        .finally(() => {
          submited.value = false;
        });
    };

    const resetFieldErrors = () => {
      for (let key in fieldErrors) {
        fieldErrors[key] = null;
      }
    };
    watch(user, (newData, oldData) => {
      resetFieldErrors();
    });
    return {
      getMsgSuccess,
      getMsgError,
      store,
      user,
      fieldErrors,
      submited,
      password,
      message_error,
      onSubmit,
      trans,
    };
  },
};
</script>
