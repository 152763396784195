export const fullData = (item, data) => {

    if (!data) {
        let data = new FormData();
    }
    if (typeof item === 'object')
        for (let key in item) {
            if (item[key]) {
                if (typeof (item[key]) === 'object') {
                    data.append(key, JSON.stringify(item[key]));
                } else if (typeof (item[key]) === 'boolean') {
                    data.append(key, item[key] ? 1 : 0);
                } else {
                    data.append(key, item[key]);
                }
            }
        }
    return data;
}
export const getStatusError = function (error) {
    if (typeof error === 'object' && error.hasOwnProperty('response') && error.response.hasOwnProperty('status')) {
        return error.response.status;
    } else {
        return null;
    }
}
export const getFieldError = function (error) {
    if (typeof error === 'object' && error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('field') && error.response.data.field) {
        return error.response.data.field;
    }
    return null;
}
export const getMsgError = function (error, $message = 'Неизвестная ошибка') {
    if (typeof error === 'object' && error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('message') && error.response.data.message) {
        return error.response.data.message;
    }/*else if(
        typeof error === 'object' &&
        error.hasOwnProperty('request') &&
        error.request.hasOwnProperty('data') &&
        error.request.data.hasOwnProperty('message') && error.request.data.message
    ){

    }*/ else if (typeof error === 'object' && error.hasOwnProperty('message')) {
        return error.message;
    } else {
        return $message;
    }
}
export const getMsgSuccess = function (response, $message = 'Успешно') {
    if (typeof response === 'object' && response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
        return response.data.message;
    } else {
        return $message;
    }
}
export const fillRequestData = function (item) {
    let form_data = new FormData();
    for (let key in item) {
        if (item[key]) {
            if (key == 'picture') {
                if (item.picture.upload) {
                    form_data.append('picture', item.picture.upload);
                }
            } else if (typeof (item[key]) === 'object') {
                form_data.append(key, JSON.stringify(item[key]));
            } else if (typeof (item[key]) === 'boolean') {
                form_data.append(key, item[key] ? 1 : 0);
            } else {
                form_data.append(key, item[key]);
            }
        }
    }
    return form_data;
}
