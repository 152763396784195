import axios from "axios";

const front_store = {
  namespaced: true,
  state: {
    user: null,
    auth_error: false,
    refresh: false,
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  mutations: {
    refresh(state) {
      state.refresh = !state.refresh;
    },

    user(state, user) {
      state.user = user;
    },
    auth_error(state, auth_error) {
      state.auth_error = auth_error;
    },
  },

  actions: {
    logout({ commit }) {
      commit("user", null);
      commit("refresh");
    },
    check_error({ commit }, error) {
      const {
        response: { status, data },
      } = error;
      if (status === 401) {
        commit("auth_error", true);
      }
    },
    user({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ajax/user`, {
            withCredentials: true,
          })
          .then((response) => {
            commit("user", response.data.user);
            resolve(response.data.user);
          })
          .catch((error) => {
            commit("user", null);
            reject(error);
          });
      });
    },
  },
};
export default front_store;
