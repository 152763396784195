<template>
  <div class="comments-container" id="comments-container" ref="comments_ref">
    <div class="comment-list-title" v-if="post_title">{{ post_title }}</div>
    <comments-pagination
      :pagination="pagination"
      v-on:update:page="page = $event"
      v-if="pagination && pagination.last_page > 1"
    />
    <div class="comments-list" v-if="comments && comments.length > 0">
      <div v-for="comment in comments" :comment="comment" :key="comment.id">
        <comment
          :comment="comment"
          @update="reload(page)"
          @destroy-comment="showModalDestroy"
        />
        <div
          class="comments-children-list"
          v-if="comment.children && comment.children.length > 0"
        >
          <comment
            v-for="child in comment.children"
            :comment="child"
            :key="child.id"
            @update="reload(page)"
            @destroy-comment="showModalDestroy"
          />
        </div>
      </div>
    </div>
    <comments-pagination
      :pagination="pagination"
      v-on:update:page="page = $event"
      v-if="pagination && pagination.last_page > 1"
    />
    <from-create :total="CommCount" v-on:created="afterCreated" />
    <DeleteModal
      :is-show="isModalDestroy"
      @modal-confirm="confirmDestroyComment"
      name="inline-comments-modal-delete"
      :message="destroyCommentItem ? destroyCommentItem.message : ''"
      @modal-close="isModalDestroy = false"
    />
  </div>
</template>
<script>
import FromCreate from "./FromCreate.vue";
import comment from "./Comment.vue";
import CommentsPagination from "./Pagination.vue";
import { useStore } from "vuex";
import FormUpdate from "./FormUpdate.vue";
import { getMsgSuccess, getMsgError } from "../../utils/requests";
import { computed, inject, onMounted, onUnmounted, ref, watch } from "vue";
import DeleteModal from "./Delete.vue";

export default {
  components: { FormUpdate, FromCreate, DeleteModal, CommentsPagination, comment },

  setup(props, ctx) {
    const store = useStore();
    store.commit("comments/post_id", inject("post_id"));
    const post_title = inject("post_title");
    const CommCount = ref(0);
    const user = computed(() => store.state.user);
    const page = ref(1);
    const comments = ref([]);
    const toast = inject("$toast");
    const loading = ref(false);
    const destroyCommentItem = ref(null);
    const isModalDestroy = ref(false);
    const pagination = computed(() => store.state.comments.comments_pagination);
    const comments_ref = ref(null);
    const reload = () => {
      store
        .dispatch("comments/comments_reload")
        .then((data) => {
          comments.value = JSON.parse(JSON.stringify(data));
        })
        .catch((err) => toast.error(getMsgError(err)));
    };

    const showModalDestroy = (commentEvent) => {
      destroyCommentItem.value = JSON.parse(JSON.stringify(commentEvent));
      isModalDestroy.value = true;
    };
    const confirmDestroyComment = () => {
      loading.value = true;
      axios
        .delete(`/ajax/comments/${destroyCommentItem.value.id}/`)
        .then((resp) => {
          destroyCommentItem.value = null;
          store.commit("comments/comments_reload");
          toast.success(getMsgSuccess(resp));
        })
        .catch((err) => {
          toast.error(getMsgError(err));
        })
        .finally(() => {
          loading.value = false;
          isModalDestroy.value = false;
        });
    };
    const unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type == "comments/comments_reload") {
        store
          .dispatch("comments/comments_reload")
          .then((data) => {
            comments.value = JSON.parse(JSON.stringify(data));
            if (!user.value) {
              store.dispatch("user").catch((err) => {});
            }
          })
          .catch((err) => toast.error(getMsgError(err)));
      }
    });

    onMounted(() => {
      store.dispatch("user").catch((err) => {});
      store
        .dispatch("comments/comments_reload")
        .then((data) => (comments.value = data))
        .catch((err) => toast.error(getMsgError(err)));
    });
    onUnmounted(() => {
      unsubscribe();
    });
    const afterCreated = () => {
      comments_ref.value.scrollIntoView({ behavior: "smooth" });
    };
    watch(page, (newData, oldData) => {
      if (newData > 0 && newData != oldData) {
        store.commit("comments/comments_page", parseInt(newData));
        store
          .dispatch("comments/comments_reload")
          .then((data) => {
            comments.value = data;
            comments_ref.value.scrollIntoView({ behavior: "smooth" });
          })
          .catch((err) => toast.error(getMsgError(err)));
      }
    });
    watch(comments, (newData, oldData) => {
      CommCount.value =
        newData && Number.parseInt(newData.total) ? Number.parseInt(newData.total) : 0;
    });
    return {
      loading,
      destroyCommentItem,
      isModalDestroy,
      page,
      comments,
      user,
      showModalDestroy,
      confirmDestroyComment,
      pagination,
      reload,
      getMsgSuccess,
      getMsgError,
      toast,
      post_title,
      CommCount,
      comments_ref,
      afterCreated,
    };
  },
};
</script>
