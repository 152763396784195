import { createStore } from "vuex";
import front_store from "./store";
import axios from "axios";
import comments from './modules/comments';
const store = createStore(front_store);
store.registerModule("comments", comments);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // const { config, response: { status } } = error
    const { config, response } = error;
    const originalRequest = config;
    // if (status === 401) {
    if (response && response.status === 401) {
      store.dispatch("logout");
    }
    return Promise.reject(error);
  }
);

export default store;
