<template>
  <div
    class="card card-comment"
    :id="`comment-${comment.id}`"
    v-if="comment"
    itemprop="comment"
    itemscope=""
    itemtype="https://schema.org/Comment"
  >
    <div class="comment-view" v-if="!(isUpdate || isReply || false)">
      <div class="comment-header" :class="{ 'comment-need-approve': comment.status }">
        <div
          :class="{
            'comment-avatar': true,
            'comment-avatar-default': !comment.user.avatar,
          }"
        >
        <img :src="comment.user.avatar" alt="" v-if="comment.user && comment.user.avatar">
        </div>
        <span
          itemprop="author"
          itemscope=""
          itemtype="https://schema.org/Person"
          class="comment-author"
          v-if="comment.user || comment.name"
          >{{ comment.user.name || comment.name }}</span
        >
      </div>
      <span class="comment-created" itemprop="dateCreated">{{ comment.created_ru }}</span>
      <p v-if="comment.status > 0" class="comment-moderation">{{ trans['Moderated'] }}</p>
      <div class="comment-body" :class="{ 'comment-need-approve': comment.status }">
        <div
          itemprop="text"
          v-if="comment.user && comment.user.is_employer"
          v-html="comment.message"
        ></div>
        <div v-else itemprop="text">{{ comment.message }}</div>
      </div>
      <div class="row align-items-center">
        <div class="col-12 text-end">
          <div class="comment-buttons-container">
            <button
              type="button"
              class="btn btn-comment-action"
              @click="isReply = true"
              v-if="comment.status == 0"
            >
              <i class="fa fa-reply"></i>
            </button>
            <button
              type="button"
              v-if="
                user &&
                (user.is_employer || (comment.user_id == user.id && comment.status))
              "
              class="btn btn-comment-action"
              @click.stop="isUpdate = true"
            >
            {{ trans['Edit'] }}
            </button>
            <button
              v-if="
                user &&
                (user.is_employer || (comment.user_id == user.id && comment.status))
              "
              class="btn btn-comment-action"
              @click.stop="$emit('destroy-comment', comment)"
            >
              {{ trans['Remove'] }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="comment-update">
      <FormReply :parent="comment" @close="isReply = false" v-if="isReply && comment" />
      <FormUpdate
        :commentInit="comment"
        @close="isUpdate = false"
        v-if="isUpdate && comment"
      />
    </div>
  </div>
</template>
<script>
import FormUpdate from "./FormUpdate.vue";
import FormReply from "./FormReply.vue";

import { getMsgError, getMsgSuccess } from "../../utils/requests";
import { computed, inject, ref, toRef, watch, } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    FormUpdate,
    FormReply,
  },
  props: {
    comment: {
      default: null,
    },
  },
  setup(props, ctx) {
    const toast = inject("$toast");
    const comment = toRef(props, "comment");
    const commentEdit = ref(null);
    const isUpdate = ref(false);
    const isReply = ref(false);
    const store = useStore();
    const trans = JSON.parse(inject("trans"));

    const post_id = computed(() => store.state.comments.post_id);
    const user = computed(() => store.state.user);
    const approve = () => {
      axios
        .post(`/ajax/comments/approve/${comment.value.id}`)
        .then((resp) => {
          store.commit("comments/comments_reload");
          toast.success(getMsgSuccess(resp));
        })
        .catch((err) => {
          toast.error(getMsgError(err));
        });
    };

    return {
      toast,
      post_id,
      comment,
      isUpdate,
      isReply,
      user,
      commentEdit,
      getMsgError,
      getMsgSuccess,
      approve,      trans,

    };
  },
};
</script>
