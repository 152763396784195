<template>
  <div>
    <div v-if="parent && parent.message">
      <blockquote class="blockquote-reply text-transform-none">
        {{ String(parent.message).replace(/<\/?[^>]+>/gi, " ") }}
      </blockquote>
    </div>
    <Form
      class="comments-form comments-form-reply"
      v-if="comment"
      @submit="onSubmit"
      name="comment-create"
      @reset="handleReset"
    >
      <div class="row g-2 justify-content-end">
        <div class="col-12">
          <FloatingTextarea
            name="message"
            :prefix="`comment-reply-message-${comment.id}`"
            rules="required"
            v-model="comment.message"
            :error="fieldErrors.message"
            :reset="resetMessage"
            :placeholder="trans['Enter text']"
            @field-reset="resetMessage = false"
          />
        </div>
        <div v-if="!user" class="col-md-6">
          <FloatingTextInput
            v-if="!user"
            name="name"
            type="text"
            autocomplete="given-name"
            :prefix="`comment-reply-password-${comment.id}`"
            :placeholder="trans['Enter name']"
            rules="required"
            v-model="comment.name"
            :error="fieldErrors.name"
            :reset="resetName"
          />
        </div>
        <div v-if="!user" class="col-md-6">
          <FloatingTextInput
            v-if="!user"
            :prefix="`comment-reply-login-${comment.id}`"
            name="email"
            type="email"
            autocomplete="email"
            rules="required|email"
            :placeholder="trans['Enter e-mail']"
            v-model="comment.email"
            :error="fieldErrors.email"
            :reset="resetEmail"
          />
        </div>
        <div class="col-6 col-sm-6 col-lg-3 col-xl-2">
          <button class="btn btn-cancel" type="button" @click.stop="$emit('close')">
            {{trans['Cancel']}}
          </button>
        </div>
        <div class="col-6 col-sm-6 col-lg-3 col-xl-2">
          <button class="btn btn-primary" type="submit" :disabled="submited">
            {{trans['Submit']}}
          </button>
        </div>
      </div>
    </Form>
    <AuthModal
      v-if="comment"
      :is-show="authShow"
      :email="comment.email"
      @login="onLogin"
    />
  </div>
</template>
<script>
import { Form, useForm } from "vee-validate";
import FloatingTextInput from "../share/FloatingTextInput.vue";
import FloatingTextarea from "../share/FloatingTextarea.vue";
import { ref, watch, onMounted, inject, computed, reactive } from "vue";
import { useStore } from "vuex";
import { getMsgError, getFieldError } from "../../utils/requests";
import AuthModal from "../share/AuthModal.vue";

export default {
  props: {
    parent: {
      default: null,
    },
  },
  components: {
    Form,
    FloatingTextInput,
    FloatingTextarea,
    AuthModal,
  },

  setup(props, ctx) {
    const toast = inject("$toast");
    const trans = JSON.parse(inject("trans"));
    const submited = ref(false);
    const comment = ref(null);
    const store = useStore();
    const post_id = computed(() => store.state.comments.post_id);
    const user = computed(() => store.state.user);
    const { emit } = ctx;
    const resetMessage = ref(false);
    const resetEmail = ref(false);
    const resetName = ref(false);

    const authShow = ref(false);
    const fieldErrors = reactive({
      email: null,
      name: null,
      message: null,
    });
    const { setFieldError, handleReset, resetForm } = useForm();

    onMounted(() => {
      store
        .dispatch("comments/comment_default")
        .then((item) => {
          comment.value = JSON.parse(JSON.stringify(item));

          comment.value.comment_id = props.parent.id;
          if (comment.value.name) {
            if (user.value && props.parent && props.parent.user_id != user.value.id) {
            } else {
              comment.value.message = comment.value.name + ", ";
            }
          }
        })
        .catch((err) => {})
        .finally(() => (submited.value = false));
    });

    const resetFieldErrors = () => {
      for (let key in fieldErrors) {
        fieldErrors[key] = null;
      }
    };

    watch(user, (newData, oldData) => {
      if (newData && newData != oldData) {
        authShow.value = false;
      }
      resetFieldErrors();
    });

    const onLogin = () => {
      onSubmit({ message: comment.message });
    };
    const onSubmit = (values) => {
      submited.value = true;
      authShow.value = false;
      resetFieldErrors();

      axios
        .post(`/ajax/comments/${post_id.value}`, {
          ...comment.value,
        })
        .then((resp) => {
          if (!user.value) {
            store.dispatch("user").catch((err) => {});
          }
          store.commit("comments/comments_reload");
          toast.success(resp.data.message);
          emit("close");
        })
        .catch((err) => {
          let field = getFieldError(err);

          if (err && err.response && err.response.status == 401) {
            authShow.value = true;
          }
          if (field) {
            setFieldError(field, getMsgError(err));
            fieldErrors[field] = getMsgError(err);
          } else {
            toast.error(getMsgError(err));
          }
        })
        .finally(() => {
          submited.value = false;
        });
    };

    return {
      user,
      toast,
      comment,
      submited,
      fieldErrors,
      handleReset,
      setFieldError,
      onSubmit,
      onLogin,
      authShow,
      resetMessage,
      resetEmail,
      resetName,
      trans,
    };
  },
};
</script>
