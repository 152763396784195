<template>
  <div>
    <Form
      class="comments-form mb-2"
      v-if="comment"
      @submit="onSubmit"
      name="comment-create"
      @reset="handleReset"
    >
      <div class="row">
        <div class="col-12">
          <FloatingTextarea
            label="Введите текст"
            name="message"
            :prefix="`comment-inline-message-${comment.id}`"
            rules="required"
            v-model="comment.message"
            :error="fieldErrors.message"
            :reset="resetMessage"
            @field-reset="resetMessage = false"
            class="mb-2"
          />
        </div>
        <div class="col-12 text-end">
          <button
            class="btn btn-sm text-uppercase btn-comment-action"
            type="button"
            @click.stop="$emit('close')"
          >
            {{ trans["Cancel"] }}
          </button>
          <button
            class="btn btn-sm text-uppercase btn-comment-action"
            type="submit"
            :disabled="submited"
          >
            {{ trans["Save"] }}
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>
<script>
import { Form, useForm } from "vee-validate";
import FloatingTextInput from "../share/FloatingTextInput.vue";
import FloatingTextarea from "../share/FloatingTextarea.vue";
import { ref, watch, onMounted, inject, computed, reactive } from "vue";
import { useStore } from "vuex";
import { getMsgError, getFieldError } from "../../utils/requests";

export default {
  props: {
    commentInit: {
      default: null,
    },
  },
  components: {
    Form,
    FloatingTextInput,
    FloatingTextarea,
  },

  setup(props, ctx) {
    const toast = inject("$toast");
    const submited = ref(false);
    const comment = ref(null);
    const store = useStore();
    const user = computed(() => store.state.user);
    const trans = JSON.parse(inject("trans"));

    const { emit } = ctx;
    const resetMessage = ref(false);

    const fieldErrors = reactive({
      message: null,
    });
    const { setFieldError, handleReset, resetForm } = useForm();
    comment.value = JSON.parse(JSON.stringify(props.commentInit));

    const resetFieldErrors = () => {
      for (let key in fieldErrors) {
        fieldErrors[key] = null;
      }
    };

    watch(user, (newData, oldData) => {
      if (newData && newData != oldData) {
        resetFieldErrors();
      }
    });

    const onLogin = () => {
      onSubmit({ message: comment.message });
    };
    const onSubmit = (values) => {
      submited.value = true;
      resetFieldErrors();
      axios
        .put(`/ajax/comments/${comment.value.id}/`, {
          ...comment.value,
        })
        .then((resp) => {
          if (!user.value) {
            store.dispatch("user").catch((err) => {});
          }

          store.commit("comments/comments_reload");

          toast.success(resp.data.message);
          emit("close");
        })
        .catch((err) => {
          let field = getFieldError(err);

          if (field) {
            setFieldError(field, getMsgError(err));
            fieldErrors[field] = getMsgError(err);
          } else {
            toast.error(getMsgError(err));
          }
        })
        .finally(() => {
          submited.value = false;
        });
    };

    return {
      user,
      toast,
      comment,
      submited,
      fieldErrors,
      handleReset,
      setFieldError,
      onSubmit,
      onLogin,
      resetMessage,
      trans,
    };
  },
};
</script>
