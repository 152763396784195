import axios from "axios";

const activities = {
  namespaced: true,
  state: () => ({
    loading: false,
    refresh: false,
    comment_default: null,
    comments_reload: null,
    comments_page: 1,
    comments_pagination: {
      current_page: null,
      from: null,
      last_page: null,
      last_page_url: null,
      next_page_url: null,
      links: null,
      path: null,
      per_page: null,
      prev_page_url: null,
      to: null,
      total: null,
    },
  }),
  getters: {
    comments_pagination(state) {
      return state.comments_pagination;
    },
  },
  mutations: {
    post_id(state, id) {
        state.post_id = id;
      },
    refresh(state) {
      state.refresh = !state.refresh;
    },
    comments_reload(state) {
      state.comments_reload = !state.comments_reload;
    },
    comment_default(state, comment_default) {
      state.comment_default = comment_default;
    },
    comments_pagination(state, data) {
      for (let key in state.comments_pagination) {
        if (data[key]) {
          if (typeof data[key] == "object") {
            if (key == "links") {
              state.comments_pagination["links"] = [];
              for (let k in data["links"]) {
                state.comments_pagination["links"].push({
                  active: Boolean(data["links"][k]["active"]),
                  label: String(data["links"][k]["label"]),
                  page: parseInt(+/\d+/.exec(data["links"][k]["url"])),
                });
              }
            } else {
              state.comments_pagination[key] = {};
              Object.assign(state.comments_pagination[key], data[key]);
            }
          } else {
            state.comments_pagination[key] = data[key];
          }
        } else {
          state.comments_pagination[key] = null;
        }
      }
    },
    comments_page(state, comments_page) {
      state.comments_page = parseInt(comments_page);
    },
  },

  actions: {
    comments_reload({ commit, state }) {
      return new Promise((resolve, reject) => {
        state.comment_pagination = {
          current_page: null,
          from: null,
          last_page: null,
          last_page_url: null,
          next_page_url: null,
          links: null,
          path: null,
          per_page: null,
          prev_page_url: null,
          to: null,
          total: null,
        };
        axios
          .get(
            `/ajax/comments/${state.post_id}/?page=${
              state.comments_page ? state.comments_page : 1
            }`
          )
          .then((resp) => {
            commit("comments_pagination", resp.data);
            resolve(resp.data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    comment_default({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.comment_default) {
          resolve(state.comment_default);
        } else {
          axios
            .get("/ajax/comments/create")
            .then((resp) => {
              commit("comment_default", resp.data);
              resolve(resp.data);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
  },
};

export default activities;
