<template>
  <nav v-if="pagination && pagination.last_page != 1">
    <ul class="pagination comment-pagination">
      <li
        class="page-item"
        :class="
          String(link.page == 0 ? 'disabled ' : ' ') + String(link.active ? 'active' : '')
        "
        aria-current="page"
        v-for="link in pagination.links"
        @click.stop="change_page(link.page)"
        :key="link.label"
      >
        <button
          class="page-link"
          v-html="link.label"
          :disabled="link.page === 0"
        ></button>
      </li>
    </ul>
  </nav>
</template>
<script>
import { computed, ref, toRef,  } from "vue";

export default {
  props: {
    pagination: {
      type: [Object, null],
    },
  },
  methods: {
    change_page(page) {
      if (page == 0) return;

      this.$emit("update:page", page);
    },
  },
  setup(props, ctx) {
    const pagination = toRef(props, "pagination");
    return {
      pagination,
    };
  },
};
</script>
