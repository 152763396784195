//privacy offer
document.addEventListener("DOMContentLoaded", function (event) {
  const btnPrivacyOffer = document.getElementById("btn-privacy-accept");
  const agreePrivacyOffer = () => {
    axios
      .post(`/ajax/privacy_offer`)
      .then((resp) => {})
      .catch((err) => {})
      .finally(() => {
        let alertPrivacyOffer = document.getElementById("alert-privacy-offer");
        if (alertPrivacyOffer) {
          alertPrivacyOffer.classList.add("removed");
          alertPrivacyOffer.addEventListener("transitionend", () => {
            alertPrivacyOffer.remove();
          });
        }
      });
  };
  if (btnPrivacyOffer) {
    btnPrivacyOffer.addEventListener("click", (e) => agreePrivacyOffer());
  }
});
