<template>
  <div>
    <textarea
      :name="name"
      :value="inputValue"
      :placeholder="placeholder"
      @input="handleInput"
      @blur="handleBlur"
      class="form-control"
      :class="{ 'is-invalid': !!errorMessage, 'is-valid': !!meta.valid }"
      :style="{ height: height + 'rem' }"
      :autocomplete="autocomplete"
    ></textarea>
    <div
      :class="{ 'text-danger': !!errorMessage, 'text-success': !!meta.valid }"
      v-show="errorMessage"
      class="error-message"
    >
    </div>
  </div>
</template>

<script>
import { toRef, watch } from "vue";
import { useField } from "vee-validate";

export default {
  props: {
    height: {
      type: Number,
      default: 10,
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: [Object, String, Function],
      default: undefined,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    labelClass: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    reset: {
      default: false,
    },
  },
  setup(props, ctx) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validte needs to know if the field name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const rules = toRef(props, "rules");
    const label = toRef(props, "label");
    const error = toRef(props, "error");
    const reset = toRef(props, "reset");
    const watchValue = toRef(props, "modelValue");
    const { emit, root } = ctx;
    const {
      errors,
      value: inputValue,
      errorMessage,
      setErrors,
      handleBlur,
      handleChange,
      resetField,
      meta,
    } = useField(props.name, rules, {
      initialValue: null,
      validateOnMount: false,
      validateOnValueUpdate: false,
    });
    inputValue.value = props.modelValue;
    const setNull = () => {
      resetField();
    };
    watch(reset, (newData, oldData) => {
      if (newData) {
        resetField();
        emit("field-reset");
      }
    });
    watch(
      error,
      (newData, oldData) => {
        setErrors(newData);
      },
      {
        deep: true,
      }
    );
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation

    watch(watchValue, (newData, oldData) => {
      if (!props.modelValue) setNull();
    });
    const handleInput = (event) => {
      handleChange(event.target.value);
      emit("update:modelValue", event.target.value);
    };

    return {
      setNull,
      errors,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      handleInput,
      meta,
      label,
    };
  },
};
</script>
