import _ from "lodash";
window._ = _;
import axios from "./libs/axios";
import "./libs/color-mode";
import "./libs/privacy-offer";
import "./libs/moment-js";
import "./libs/bootstrap";
import "./libs/swiper";
import { createApp } from "vue";
import "./libs/vee-validate";
import store from "./frontend/store/index";
import VueToast from "vue-toast-notification";
import CommentsApp from "./frontend/comments/List.vue";
import LazyLoad from "vanilla-lazyload";

//onload

document.addEventListener("DOMContentLoaded", function (event) {
  const myLL = new LazyLoad({
    elements_selector: `.lazy, .post-content img`,
    use_native: true, // This one
  });
  //top menu dropdown click go
  const dpMenuTopList = document.querySelectorAll(".menu-top .dropdown > a");
  dpMenuTopList.forEach((dpMenuEl) => {
    dpMenuEl.addEventListener("click", (e) => {
      e.preventDefault();
      location.href = e.target.parentNode.href;
    });
  });

  document
    .querySelectorAll(".coin-marquee-header")
    .forEach((el) => el.remove());
  //bookmarks
  const toastContainer = document.getElementById("toast-container");
  const bookmarksList = document.querySelectorAll(".btn-bookmark");
  bookmarksList.forEach((bookmarkEl) => {
    bookmarkEl.addEventListener("click", (e) => {
      e.preventDefault();
      e.target.setAttribute("disabled", true);
      axios
        .post(`/ajax/bookmarks/` + e.target.dataset.id)
        .then((resp) => {
          e.target.classList.toggle("is_active");
          //показать подсказку
          const toastEl = document.createElement("div");
          toastEl.classList.add(
            "toast",
            "align-items-center",
            "text-white",
            "border-0"
          );
          if (resp.data.state) {
            toastEl.classList.add("text-bg-primary");
          } else {
            toastEl.classList.add("text-bg-danger");
          }
          toastEl.innerHTML =
            '<div class="d-flex"><div class="toast-body"></div><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div>';
          toastEl.querySelector(".toast-body").textContent = resp.data.message;
          toastContainer.append(toastEl);
          let toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastEl);
          toastBootstrap.show();
        })
        .catch((err) => {
          // Показать ошибку
          console.log(err);
        })
        .finally(() => {
          e.target.removeAttribute("disabled");
        });
    });
  });

  //comments
  if (document.body.contains(document.getElementById("comments_list"))) {
    const appCommentsList = createApp(CommentsApp);
    appCommentsList.provide(
      "post_id",
      document.getElementById("comments_list").dataset.id
    );
    appCommentsList.provide(
      "post_title",
      document.getElementById("comments_list").dataset.title
    );
    appCommentsList.provide(
      "trans",
      document.getElementById("comments_list").dataset.trans
    );
    appCommentsList.provide(
      "profile",
      document.getElementById("comments_list").dataset.profile
    );
    appCommentsList.use(store);
    appCommentsList.use(VueToast);
    appCommentsList.mount("#comments_list");
  }

  //upload avatar
  const uploadAvatarBtn = document.getElementById("btn-change-avatar");
  const UserAvatarImage = document.getElementById("user-avatar-image");
  if (uploadAvatarBtn && UserAvatarImage) {
    UserAvatarImage.style.cursor = "pointer";
    const uploadAvatarFile = document.getElementById("file-avatar-upload");
    uploadAvatarFile.addEventListener("change", function () {
      if (uploadAvatarFile.files.length > 0) {
        const avatarUploadForm = document.getElementById("avatarUploadForm");
        if (avatarUploadForm) {
          avatarUploadForm.submit();
        }
      }
    });
    UserAvatarImage.addEventListener("click", function () {
      uploadAvatarFile.click();
    });
    uploadAvatarBtn.addEventListener("click", function () {
      uploadAvatarFile.click();
    });
  }
});
