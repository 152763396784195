<template>
  <div
    class="modal modal-comments fade"
    :id="modalName"
    tabindex="-1"
    :aria-labelledby="`${modalName}Label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h6 class="post-item-title" :id="`${modalName}Label`">
            <strong> {{ trans["Remove?"] }}</strong>
          </h6>
          <div class="pb-4">
            <div class="text-truncate">
              {{ message }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex flex-row justify-content-between">
              <button
                type="button"
                class="btn btn-sm text-uppercase btn-comment-action btn-comment-action-danger"
                @click="confirmModal"
              >
              {{ trans["Remove"] }}
              </button>
              <button
                type="button"
                class="btn btn-sm text-uppercase btn-comment-action"
                data-bs-dismiss="modal"
              >
              {{ trans["Cancel"] }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
import { watch, ref, toRef, onUnmounted, onMounted, inject } from "vue";

export default {
  name: "DeleteModal",
  inheritAttrs: false,
  props: {
    isShow: {
      default: false,
    },
    message: {
      default: "",
    },
    name: {
      default: "delete-modal",
    },
  },
  setup(props, { emit }) {
    const modal = ref(null);
    const isShow = toRef(props, "isShow");
    const modalName = toRef(props, "name");
    const trans = JSON.parse(inject("trans"));

    const confirmModal = () => {
      emit("modal-confirm");
      modal.value.hide();
    };
    onMounted(() => {
      modal.value = new Modal(document.getElementById(modalName.value), {
        keyboard: false,
        backdrop: true,
      });
      document
        .getElementById(modalName.value)
        .addEventListener("hidden.bs.modal", function (event) {
          emit("modal-close");
        });
    });
    onUnmounted(() => {
      modal.value.dispose();
    });
    watch(isShow, (newData, oldData) =>
      newData ? modal.value.show() : modal.value.hide()
    );

    return {
      modal,
      confirmModal,
      modalName,
      trans,
    };
  },
};
</script>
