<template>
  <div
    class="modal modal_with_form fade"
    :id="modalName"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">{{ trans['Authorization'] }}</div>
          <button
            type="button"
            class="btn-close pull-right"
            @click="modal.hide()"
          ></button>
        </div>
        <div class="modal-body">
          <p class="text-error mb-0">{{trans['Account exists!']}}</p>
          <p class=""><small>{{trans['Please enter a password']}}</small></p>
          <login @login="onLogin" :email="email" :show-email="showEmail" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
import { watch, ref, toRef, onBeforeUnmount, onMounted,inject } from "vue";
import login from "./Login.vue";
import { useStore } from "vuex";

export default {
  inheritAttrs: false,
  components: {
    login: login,
  },
  props: {
    isShow: {
      default: false,
    },
    reload: {
      default: false,
    },
    email: {},
    name: {
      default: "auth-modal",
    },
    showEmail: {
      default: true,
    },
    prefix: {
      default: "auth-form",
    },
    id: {
      default: "",
    },
  },
  setup(props, ctx) {
    const modal = ref(null);
    const isShow = toRef(props, "isShow");
    const modalName = toRef(props, "name");
    const trans = JSON.parse(inject("trans"));

    const store = useStore();
    const { emit } = ctx;
    const confirmModal = () => {
      emit("modal-confirm");
      modal.value.hide();
    };
    const onLogin = () => {
      modal.value.hide();
      setTimeout(() => {
        store
          .dispatch("user")
          .then((resp) => {
            if (props.reload) {
              // window.location.reload(true);
            }
            emit("login");
          })
          .catch(() => true);
      }, 1000);
    };
    onMounted(() => {
      modal.value = Modal.getOrCreateInstance(document.getElementById(modalName.value), {
        keyboard: false,
        backdrop: true,
      });
    });
    onBeforeUnmount(() => {
      modal.value.hide();
    });

    watch(isShow, (newData, oldData) =>
      newData ? modal.value.show() : modal.value.hide()
    );
    return {
      modal,
      confirmModal,
      modalName,
      onLogin,trans,
    };
  },
};
</script>
