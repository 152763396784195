document.addEventListener("DOMContentLoaded", function (event) {
  const toggleThemeInput = document.getElementById("input-theme-switch");
  if (toggleThemeInput) {
    const setTheme = () => {
      if (!toggleThemeInput) {
        return;
      }
      axios
        .post("/ajax/theme", {
          theme: toggleThemeInput.checked ? "dark" : "light",
        })
        .then((resp) => {
          document.documentElement.setAttribute(
            "data-bs-theme",
            toggleThemeInput.checked ? "dark" : "light"
          );
          if (document.getElementById("coinmarketcap-widget-marquee")) {
            document
              .getElementById("coinmarketcap-widget-marquee")
              .setAttribute(
                "theme",
                toggleThemeInput.checked ? "dark" : "light"
              );
          }
        })
        .catch((err) => {});
    };
    toggleThemeInput.addEventListener("click", function (e) {
      setTheme();
    });
  }
});
//
