import Swiper, {
  Navigation,
  Pagination,
  Thumbs,
  Virtual,
  FreeMode,
  Autoplay,
} from "swiper";
Swiper.use([Navigation, Pagination, Thumbs, Virtual, FreeMode, Autoplay]);
document.addEventListener("DOMContentLoaded", function (event) {
  if (document.getElementById("swiper-recommendations")) {
    const recommendations = new Swiper("#swiper-recommendations", {
      loop: true,

      slidesPerView: 1,

      // Responsive breakpoints
      breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    });
  }
});
export default Swiper;


