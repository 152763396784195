//import "@popperjs/core";
import { Tooltip, Collapse, Dropdown, Offcanvas, Alert } from "bootstrap";
document.addEventListener("DOMContentLoaded", function (event) {
  /*collapses */
  const collapseElementList = document.querySelectorAll(".collapse");
  const collapseList = [...collapseElementList].map(
    (collapseEl) => new Collapse(collapseEl)
  );
  /*dropdowns */
  const dropdownElementList = document.querySelectorAll(".dropdown-toggle");
  const dropdownList = [...dropdownElementList].map(
    (dropdownToggleEl) => new Dropdown(dropdownToggleEl)
  );
  /*tooltips*/
  const tooltipElementList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  const tooltipList = [...tooltipElementList].map(
    (tooltipEl) => new Tooltip(tooltipEl)
  );
  /*tabs*/
  /*   const triggerTabList = document.querySelectorAll("#myTab a");
  triggerTabList.forEach((triggerEl) => {
    const tabTrigger = new bootstrap.Tab(triggerEl);

    triggerEl.addEventListener("click", (event) => {
      event.preventDefault();
      tabTrigger.show();
    });
  }); */
  /*offcanvas */
  const offcanvasElementList = document.querySelectorAll(".offcanvas");
  const offcanvasList = [...offcanvasElementList].map(
    (offcanvasEl) => new Offcanvas(offcanvasEl)
  );
  /*popper */
  /*   const popoverTriggerList = document.querySelectorAll(
    '[data-bs-toggle="popover"]'
  );
  const popoverList = [...popoverTriggerList].map(
    (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl)
  ); */
  /*alerts*/
  const alertList = document.querySelectorAll(".alert");
  const alerts = [...alertList].map((element) => new Alert(element));
  /*toasts*/
  /*  const toastsList = document.querySelectorAll(".toast");
  const toasts = [...toastsList].map((element) => {
    new bootstrap.Toast(element);
  });

 */
});

